import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const aroundMyFrenchTable = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me cook recipes from Dorie Greenspan's cookbook, Around My French Table.";

  return (
    <Layout>
      <Seo title="Around My French Table" meta={metaData} image={image} description={description}/>
      <h1>Around My French Table</h1>
      <p>
        <a href="https://doriegreenspan.com/">Dori Greenspan</a> is an American who lives part time in
        Paris, which is a dream for many. Despite getting a late start on cooking (after a kitchen disaster
        at the age of 12), she has written 14 cookbooks and won five <a href="https://www.jamesbeard.org/awards">
        James Beard awards</a>.
      </p>
      <p>
        Most of her books are about baking, but <a href="https://www.goodreads.com/book/show/7847439-around-my-french-table">
        Around My French Table</a> is more of an everything book. It was first published in 2010, but 
        the recipes in it are evergreen, like most French cuisine. Classics like French onion soup, roast 
        chicken, and French apple cake are always on-trend in my house.
      </p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/around my french table/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default aroundMyFrenchTable;
